@import "../../styles/variables";

$button-size: 2.5rem;

.forecastDay {
  align-items: center;
  display: grid;
  grid-column-gap: 0.75rem;
  grid-template-columns: 1.25rem 5rem 1fr $button-size;
  max-width: 100%;
  overflow: hidden;
  padding: 1rem 0;
  position: relative;

  @include respond-to(small) {
    grid-column-gap: 1rem;
    grid-template-columns: 2.5rem 8rem 1fr $button-size;
  }

  @include respond-to(medium) {
    grid-template-columns: 3rem 30% 1fr $button-size;
  }

  &Meta {
    min-width: 45%;
  }
}

.day {
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.325rem;

  @include respond-to(small) {
    font-size: 1.25rem;
  }
}

.summary {
  display: none;
  font-size: 0.75rem;
  margin: 0 0 0.5rem;
  opacity: 0.85;

  @include respond-to(medium) {
    display: block;
    font-size: 1rem;
  }
}

.icon {
  align-self: top;
  color: $grey-medium;
  font-size: 1.325rem;

  @include respond-to(small) {
    font-size: 2.75rem;
  }
}

.hourBreakout {
  margin: 0 0 1rem;

  &Row {
    @include respond-to(medium) {
      display: flex;
      justify-content: space-between;
    }

    p {
      position: relative;
      top: -0.25rem;
    }
  }
}
