@import "../../styles/variables";

$day-width: 1 / 12 * 100 * 1%;

.hourlyForecast {
  margin: 1rem 0;

  @include respond-to(medium) {
    display: grid;
    grid-template-columns: repeat(auto-fit, $day-width);
    padding: 0;
    text-align: center;
  }

  li:first-of-type & {
    justify-content: flex-end;
  }
}

.forecastSummary {
  margin: 0 0 1rem;
  max-width: 30rem;
}
