@use "sass:math";
@import "../../styles/variables";

$current-meter: 6rem;
$current-border: $border-width * 2;
$current-size: 1rem;

.current {
  height: $current-meter * 2;
  position: relative;
  text-align: center;
  width: $current-meter * 2;
  z-index: 100;

  &Container {
    flex: 0 0 auto;
    max-height: $current-meter + 2rem;
    overflow: hidden;
    padding: 0 1rem;
  }

  &Track {
    background: $grey-light;
    background: linear-gradient(to right, var(--temp-low), var(--temp-high));
    border-top-left-radius: $current-meter * 2;
    border-top-right-radius: $current-meter * 2;
    height: $current-meter;
    mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='300' height='300' viewBox='0 0 300 300'%3E%3Cpath d='M150,276 C219.587878,276 276,219.587878 276,150 C276,80.4121215 219.587878,24 150,24 C80.4121215,24 24,80.4121215 24,150 C24,219.587878 80.4121215,276 150,276 Z M150,300 C67.1572875,300 0,232.842712 0,150 C0,67.1572875 67.1572875,0 150,0 C232.842712,0 300,67.1572875 300,150 C300,232.842712 232.842712,300 150,300 Z'/%3E%3C/svg%3E") 0 0;
    mask-repeat: no-repeat;
    mask-size: cover;
    position: relative;
    width: $current-meter * 2;
  }

  &::after {
    $half-size: math.div($current-size, 2);

    background-color: var(--marker-color);
    border: $current-border solid $white;
    border-radius: 50%;
    content: "";
    display: block;
    height: $current-size;
    left: 50%;
    margin: -($half-size);
    position: absolute;
    top: 50%;
    transform:
      rotate(var(--current-position))
      translate($current-meter - $half-size);
    width: $current-size;
  }

  &Temperature {
    font-size: math.div($current-meter, 1.75);
    font-weight: 900;
    position: relative;
    top: -1em;
  }

  &Label {
    bottom: $current-meter - $current-size - 1rem;
    font-size: 1.25em;
    font-weight: 600;
    position: absolute;

    &:first-of-type {
      left: -0.25em;
    }

    &:last-of-type {
      right: -0.5em;
    }
  }
}
