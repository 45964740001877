.sun {
  color: orange;
  font-size: 1.5rem;

  &Item {
    display: inline-block;
    padding-bottom: 1rem;
    padding-right: 1rem;
  }
}
