@import "../../styles/variables";

.button {
  background: none;
  border: 0;
  color: $blue;
  font-size: 1rem;
  margin-top: 1rem;

  @include respond-to(medium) {
    margin: 0;
  }
}

.icon {
  bottom: -0.125em;
  fill: currentcolor;
  height: 1em;
  margin-right: 0.5rem;
  position: relative;
  vertical-align: baseline;
  width: 1em;
}
