@import "../../styles/variables";

.dailyForecast {
  margin: 1rem 0 0;
  max-width: 100%;

  > li {
    &:not(:last-child) {
      border-bottom: $border;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.forecastSummary {
  line-height: 1.5;
  margin: 0 0 1rem;
  max-width: 30rem;
}
