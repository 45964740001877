@import "../../styles/variables";

.precipitation {
  color: $blue;
  font-size: 0.75rem;

  @include respond-to(medium) {
    font-size: 0.875rem;
  }
}
