@import "../../styles/variables";

.location {
  &Name {
    font-size: 2.5rem;
    font-weight: 900;
    margin: 2rem 0 1rem;
  }

  &Coordinates {
    opacity: 0.75;
  }

  &Refresh {
    display: inline-block;
    margin-left: 0.5rem;
    opacity: 0.75;

    &::before {
      content: "\2022";
      display: inline-block;
      margin-right: 0.5rem;
    }
  }
}
