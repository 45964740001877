.nav {
  display: flex;
  gap: 1.5rem;
  margin: 1rem 0 2rem;

  &Item {
    border-bottom: 0.25rem solid transparent;
    padding-bottom: 0.5rem;
  }
}

.active {
  border-color: currentcolor;
}
