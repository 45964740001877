@import "../../styles/variables";

.forecast {
  &Header {
    margin: 0 0 3rem;

    @include respond-to(large) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &Info {
    flex: 1 1 auto;
    margin: 2rem 0;

    @include respond-to(large) {
      margin-top: 0;

      &:not(:first-child) {
        margin-left: 3rem;
      }
    }
  }

  &Conditions {
    align-items: center;
    display: flex;
    margin-bottom: 2rem;

    h2 {
      font-weight: 900;
      margin: 0 0 0.5rem;
    }
  }

  &Text {
    margin: 0 0 1rem;
  }
}

.icon {
  font-size: 4rem;
  margin-right: 1.25rem;
  opacity: 0.5;
}
