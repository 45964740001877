@import "../../styles/variables";

.button {
  align-self: center;
  background: none;
  border: 0;
  color: $grey-medium;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0;
  position: relative;
  z-index: 100;

  &:focus {
    outline: none;
  }
}
