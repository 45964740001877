@use "sass:map";

$breakpoints: (
  "small": $small,
  "medium": $medium,
  "large": $large
);

// http://www.sitepoint.com/managing-responsive-breakpoints-sass/
@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map.get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. ";
  }
}
