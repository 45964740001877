@import "./variables";

:root {
  --background-image: none;
  --background-color: rgb(255 255 255 / 93%);
  --current-position: 180deg;
  --temp-low: hsl(209deg 85% 50%);
  --marker-color: var(--temp-low);
  --temp-high: hsl(12deg 85% 50%);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  background-color: var(--background-color);
}

html {
  height: 100%;
}

body {
  background-attachment: fixed;
  background-image: var(--background-image);
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-height: 100%;
  padding: 0 0 4rem;
  position: relative;

  @include respond-to(large) {
    padding: 2rem 0 4rem;
  }
}

code {
  font-family: $font-family-mono;
}

.list-clear {
  list-style: none;
  padding: 0;
}

.container {
  padding: 1rem;
}

a {
  color: $blue;
  text-decoration: none;

  &:visited {
    color: $blue;
  }
}

.svg-icon {
  fill: currentcolor;
  height: 1em;
  margin-right: 0.25em;
  vertical-align: middle;
  width: 1em;
}

.header--strong {
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  margin-top: 0;
  text-transform: uppercase;

  @include respond-to(medium) {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
}

figure {
  margin: 0;
  padding: 0;
}

select,
input[type="text"],
input[type="number"] {
  font-size: inherit;
}

@import "../../node_modules/pikaday/css/pikaday.css";
@import "./datepicker";
