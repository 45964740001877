@use "sass:math";
@import "../../styles/variables";

.uv {
  border-radius: 100%;
  color: $white;
  display: inline-block;
  font-size: 0.875rem;
  height: 1.25rem;
  margin-left: 0.325em;
  padding: math.div(0.125, 2) * 1em;
  position: relative;
  text-align: center;
  vertical-align: center;
  width: 1.25rem;
}

.exLow {
  background-color: $green;
}

.low {
  background-color: $yellow;
  color: $grey;
}

.med {
  background-color: $orange;
}

.high {
  background-color: $red;
}

.exHigh {
  background-color: $purple;
}
