@import "../../styles/variables";

.map {
  background: $grey-light;
  height: 22rem;
  margin: 0 -0.5rem 2rem;
  width: calc(100% + 1rem);

  @include respond-to(medium) {
    height: 28rem;
    margin: 0 -1rem 2rem;
    width: calc(100% + 2rem);
  }

  @include respond-to(large) {
    margin: 0 -2rem 2rem;
    width: calc(100% + 4rem);
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}
