@import "../../styles/variables";

.button {
  background: $blue;
  border: 0;
  color: $white;
  font-size: 1.125rem;
  padding: 0.5em;

  &:focus {
    outline: none;
  }
}
