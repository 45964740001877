@import "../../styles/variables";

.location {
  margin-bottom: 1.5rem;
}

.row {
  @include respond-to(medium) {
    display: flex;
    margin: 0;
  }
}

.typeahead {
  position: relative;
}
