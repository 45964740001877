@import "../styles/variables";

.app {
  background: var(--background-color);
  margin: auto;
  max-width: $large;
  padding: 1rem 0.5rem;
  position: relative;

  @include respond-to(medium) {
    padding: 1rem;
  }

  @include respond-to(large) {
    padding: 2rem;
  }
}
