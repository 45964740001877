@import "../../styles/variables";

.forecastHour {
  align-items: center;
  display: grid;
  grid-template-columns: 0.75rem 4.5rem 5rem 1fr;
  position: relative;

  @include respond-to(medium) {
    grid-row-gap: 0.5rem;
    grid-template-columns: auto;
    grid-template-rows: 1.75rem 1.5em 1.5em;
    text-align: center;
  }

  &Temps {
    grid-template-columns: 0.75rem 4.5rem 5rem 1fr;

    @include respond-to(medium) {
      grid-row-gap: 0.5rem;
      grid-template-columns: auto;
      grid-template-rows: 1fr 1.75rem 1.5em 1.5em;
      height: 18rem;
      text-align: center;
    }
  }
}

.day {
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 1rem;
  padding: 0.75rem 0;
  text-align: right;

  @include respond-to(medium) {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  @include respond-to(large) {
    font-size: 1rem;
  }
}

.summary {
  font-size: 0.75rem;
  margin: 0 1rem;
  opacity: 0.85;
}

.temperatures {
  margin: 0 1rem;
  min-height: 1.5rem;
  order: 3;
  position: relative;
  text-align: right;

  @include respond-to(medium) {
    display: none;
    height: calc(100% - 2rem);
    margin: 0;
    order: 0;
    text-align: center;
  }

  .forecastHourTemps & {
    display: block;
  }
}

.icon {
  width: 100%;
}

.secondaryTemperature {
  display: none;

  @include respond-to(medium) {
    display: block;
  }
}
