@use "sass:math";
@import "../../styles/variables";

$triangle-size: 0.325em;

.wind {
  display: inline-block;
  height: 1em;
  margin-left: 0.25em;
  position: relative;
  width: 1em;
}

.bearing {
  $triangle-size-half: math.div($triangle-size, 2);

  border-left: $triangle-size solid transparent;
  border-right: $triangle-size solid transparent;
  border-top: $triangle-size solid $red;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  margin-left: -($triangle-size);
  margin-top: -($triangle-size-half);
  position: absolute;
  top: 50%;
  width: 0;
}
