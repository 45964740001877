@import "../../styles/variables";

.chart {
  height: 8rem;
  margin: 0;
  padding: 0;

  &Title {
    margin: 0;
  }
}
