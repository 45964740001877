@import "../styles/variables";

.timemachineHeading {
  font-weight: 900;
  margin: 3rem 0 0;
}

.timemachineHeader {
  margin-top: 1.5rem;

  @include respond-to(medium) {
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: min-content 1fr;
  }
}
