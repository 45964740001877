@import "../../styles/variables";

.stats {
  column-gap: 1.5rem;
  display: grid;
  flex: 0 0 100%;
  grid-template-columns: repeat(2, 50%);
  margin-bottom: 1.5rem;
  row-gap: 1rem;

  @include respond-to(medium) {
    grid-template-columns: repeat(4, max-content);
  }

  @include respond-to(large) {
    grid-template-columns: repeat(7, max-content);
    padding-left: 0;
  }

  &Narrow {
    @include respond-to(medium) {
      grid-template-columns: repeat(3, max-content);
    }

    @include respond-to(large) {
      grid-template-columns: repeat(4, max-content);
    }
  }
}
