@import "../../styles/variables";

.range {
  margin: 0 0 1.5rem;
  position: relative;
  top: -0.125em;

  &Icon {
    bottom: 0.125rem;
    color: $grey-medium;
    margin: 0 1rem;
    position: relative;
  }
}

.temperature {
  font-size: 1.325rem;
  margin-right: 0.25rem;
}
