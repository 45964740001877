@import "../../styles/variables";

$temp-size: 2rem;

.temp {
  align-items: center;
  background: $grey;
  border-radius: 50%;
  color: $white;
  display: inline-flex;
  font-size: 0.875rem;
  height: $temp-size;
  justify-content: center;
  margin-bottom: -0.25em;
  margin-left: -1.5em;
  padding: 0.25rem 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s linear;
  width: $temp-size;

  &[style] {
    @media (max-width: $medium) {
      bottom: 0 !important;
    }

    @include respond-to(medium) {
      bottom: inherit;
      left: 50% !important;
    }
  }

  @include respond-to(medium) {
    left: 50%;
    margin-bottom: -1rem;
    margin-left: -1em;
  }
}
