@use "sass:color";
@import "../../styles/variables";

$label-size: 2rem;

.tempRange {
  align-self: center;
  height: 1em;
  margin: 0 $label-size;
  position: relative;

  &Bar {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    text-align: right;
    top: 0.325rem;
    width: 100%;
  }
}

.range {
  // background: lighten($grey, 25%);
  background: color.adjust($grey, $lightness: 25%);
  border-radius: 0.5rem;
  display: block;
  height: 0.5rem;
  margin: 0 0.325rem;
  min-width: 2%;
  position: relative;
}

.rangeValue {
  font-size: 0.875rem;
  position: absolute;
  top: -0.325em;

  &:first-child {
    left: -($label-size - 0.25rem);
  }

  &:last-child {
    right: -($label-size - 0.25rem);
  }

  @include respond-to(small) {
    font-size: 1rem;
  }
}
